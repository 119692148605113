var app = new Vue({
    i18n,
    el: '#vue',
    data: {
        is_logged_in: is_logged_in,
        app_url: app_url,
        form: false,
        template_id: null,
        exit_intent_popup: false,
        email: "",
    },
    methods: {
        showRegisterForm() {
            return this.showForm();
        },
        showRegisterFormWithTemplate(template_id) {
            return this.showForm(template_id);
        },
        showForm(template_id = null) {
            if (!this.is_logged_in) {
                this.form = true;
                this.template_id = template_id;
            } else {
                window.location.href = this.app_url;
            }
        },
        onLeave() {
            if (!this.form) {
                this.exit_intent_popup = true;
            }
        },
        intentPopupShowRegisterForm(email) {
            this.exit_intent_popup = false;
            this.email = email;
            this.form = true;
        },
        getStarted(event) {
            if (event.target.classList.contains('skip-getting-started')) {
                this.showRegisterForm();
            } else {
                var link = event.target.getAttribute('href');
                window.location.href = link;
            }
        }

    },
    mounted() {
        if (ref) {
            $cookies.set('user_refferal', ref);
        }
    }
});